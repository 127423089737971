import React, { useEffect, useState, VoidFunctionComponent } from 'react';

const DAYS_MAP = {
  Monday: 'Pondelok',
  Tuesday: 'Utorok',
  Wednesday: 'Streda',
  Thursday: 'Štvrtok',
  Friday: 'Piatok',
  Saturday: 'Sobota',
  Sunday: 'Nedeľa',
};

const Contact: VoidFunctionComponent = () => {
  const [state, setState] = useState<string[]>([]);
  useEffect(() => {
    function init() {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: {
          lat: 48.1524901,
          lng: 17.0621499,
        },
        zoom: 15,
      });
      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId: 'ChIJd3NqGHCFbEcRGLylPXKI33w',
        },
        (place: any, status: any) => {
          new window.google.maps.Marker({
            map: map,
            position: place.geometry.location,
          });
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            map.setCenter(place.geometry.location);
            setState(place.opening_hours.weekday_text);
          }
        },
      );
    }
    try {
      init();
    } catch {
      setTimeout(init, 1000);
    }
    // console.log(window.google);
  }, []);
  return (
    <>
      <section className="main-section main-section--right main-section--last" id="kontakt">
        <div className="container main-section__container main-section__container--map">
          <div id="map" className="map" />
          <div className="main-section__content">
            <h1 className="main-section__title main-section__title--left" data-title="Kontakt">
              Kontakt
            </h1>
            <div className="map-cols">
              <div className="map-cols__item">
                <h2>Centrála</h2>
                <p>
                  Karloveská 6C <br />
                  841 04, Bratislava - Karlova Ves
                </p>
                <a href="#navigovat">Navigovať</a>
              </div>
              <div className="map-cols__item">
                <h2>Informácie</h2>
                <p>
                  Telefón: <a href="tel:+421944411758">+421 944 411 758</a>, <br />
                  <a href="tel:+421903722288">+421 903 722 288 (konateľ)</a>
                  <br />
                  E-mail: <a href="mailto:info@slyze.sk">info@slyze.sk</a>
                </p>
                <p>
                  <a href="#podmienky">Podmienky pre spracovanie osobných údajov</a>
                </p>
              </div>
            </div>
            <h2>Otváracie hodiny</h2>
            <p>
              {state.map((value) => (
                <span key={value}>
                  {value.replace(
                    /Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday/,
                    (match) => DAYS_MAP[match as keyof typeof DAYS_MAP],
                  )}{' '}
                  <br />
                </span>
              ))}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
