import React, { VoidFunctionComponent } from 'react';
import Skiing from './img/skiing.png';

const Sale: VoidFunctionComponent = () => {
  return (
    <>
      <section className="main-section main-section--left main-section--padding main-section--margin">
        <div className="container main-section__container main-section__container--video">
          <div className="main-section__content">
            <h1 className="main-section__title main-section__title--left" data-title="Predaj">
              Predaj
            </h1>
            <p className="main-section__text main-section--left">
              Predávame nové a testovacie lyže 2020/2021 za bezkonkurenčné ceny, príďte si ich ku nám vyskúšať.
            </p>
            <p>
              <a className="button button--primary" href="https://eshop.slyze.sk/">
                Prejsť do e-shopu
              </a>
              <a className="button button--blue" href="https://services.bookio.com/slyze/widget?lang=sk">
                Rezervovať termín
              </a>
            </p>
          </div>
          <div className="video video--right">
            <iframe
              className="video__player"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/7zK-hKtjXfA?controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* <!-- <img src="/dist/img/predaj-1.jpg" className="image-wrap__img" /></div> --> */}
          </div>
        </div>
      </section>
      <section className="main-section main-section--right main-section--padding" id="bazar">
        <div className="container main-section__container main-section__container--video">
          <div className="main-section__content">
            <h1 className="main-section__title main-section__title--right" data-title="Bazár">
              Bazár
            </h1>
            <p className="main-section__text main-section--right">
              Náš bohatý sortiment v cenách od od 30 &euro; určite osloví aj tých najnáročnejších. Príďte sa ku nám
              pozrieť, alebo nám zavolajte.
            </p>
            <p className="main-section__text main-section--right">
              <a className="button button--primary" href="https://eshop.slyze.sk/bazar">
                Prejsť do bazáru
              </a>
              &nbsp;
              <a className="button button--blue" href="https://services.bookio.com/slyze/widget?lang=sk">
                Rezervovať termín
              </a>
            </p>
          </div>
          <div className="video video--left">
            <iframe
              className="video__player"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/8cjZmlvKfHM?controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* <!-- <img src="/dist/img/predaj-1.jpg" className="image-wrap__img" /></div> --> */}
          </div>
        </div>
      </section>
      <section className="main-section main-section--left" id="o-nas">
        <div className="container main-section__container">
          <div className="main-section__content">
            <h1 className="main-section__title main-section__title--left" data-title="O nás">
              O nás
            </h1>
            <div className="lists">
              <ul className="fa-ul list">
                <li className="list__item">
                  <i className="fa fa-li fa-snowflake-o list__icon" aria-hidden="true"></i>Servis a požičovňa lyží
                  a&nbsp;snowboardov pre&nbsp;vás už 9 rokov
                </li>
                <li className="list__item">
                  <i className="fa fa-li fa-snowflake-o list__icon" aria-hidden="true"></i>Bezkonkurenčná rýchlosť
                  poskytovaných služieb
                </li>
                <li className="list__item">
                  <i className="fa fa-li fa-snowflake-o list__icon" aria-hidden="true"></i>Profesionálne stroje značky
                  WINTERSTEIGER, MONTANA,&hellip;
                </li>
                <li className="list__item">
                  <i className="fa fa-li fa-snowflake-o list__icon" aria-hidden="true"></i>Najlacnejšia požičovňa lyží
                  a&nbsp;snowboardov pre&nbsp;každého
                </li>
              </ul>
              <ul className="fa-ul list">
                <li className="list__item">
                  <i className="fa fa-li fa-snowflake-o list__icon" aria-hidden="true"></i>Profesionálny osobný prístup
                  a&nbsp;príjemné prostredie
                </li>
                <li className="list__item">
                  <i className="fa fa-li fa-snowflake-o list__icon" aria-hidden="true"></i>Bezkonkurenčné otváracie
                  hodiny na&nbsp;celom Slovensku
                </li>
                <li className="list__item">
                  <i className="fa fa-li fa-snowflake-o list__icon" aria-hidden="true"></i>Požičovňa lyží
                  s&nbsp;obrovským sortimentom
                </li>
              </ul>
            </div>
          </div>
          <img src={Skiing} className="main-section__image right" />
        </div>
      </section>
    </>
  );
};

export default Sale;
