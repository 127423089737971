import { Link } from 'gatsby';
import React, { VoidFunctionComponent } from 'react';
import './featured-nav.scss';

const FeaturedNav: VoidFunctionComponent = () => {
  return (
    <section className="featured-nav js-menu">
      <div className="container featured-nav__container">
        <a href="https://eshop.slyze.sk/lyze/" className="featured featured--3">
          <div className="featured__content featured__content--large">
            <span className="featured__title">Predaj</span>
            <p className="featured__text">Predaj nových kvalitných lyží za rozumnú cenu.</p>
          </div>
        </a>
        <Link className="featured featured--2" to="/pozicovna">
          <div className="featured__content">
            <span className="featured__title">Požičovňa</span>
            <p className="featured__text">Každý rok požičiavame to najlepšie z lyží a snowboardov. </p>
          </div>
        </Link>
        <a className="featured featured--4" href="https://eshop.slyze.sk/bazar/">
          <div className="featured__content">
            <span className="featured__title">Bazár</span>
            <p className="featured__text">Náš bohatý sortiment určite osloví aj tých najnáročnejších.</p>
          </div>
        </a>
        <Link className="featured featured--1" to="/servis">
          <div className="featured__content">
            <span className="featured__title">Servis</span>
            <p className="featured__text">Servis lyží a snowboardov do 24 hodín</p>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default FeaturedNav;
