import React, { VoidFunctionComponent } from 'react';
import './reviews.scss';

const Reviews: VoidFunctionComponent = () => {
  return (
    <section
      className="main-section main-section--center main-section--padding"
      id="recenzie"
      style={{ marginTop: 120 }}
    >
      <div className="container">
        <h1 className="main-section__title main-section__title--center" data-title="Recenzie">
          Recenzie
        </h1>
        <p className="main-section__text main-section__text--center">
          Sme najlepšie hodnotený servis a požičovňa lyží v Bratislave.
        </p>
        <div className="testimonials">
          <div className="testimonial">
            <header className="testimonial__header">
              <h2 className="testimonial__title">Michal Kučiak</h2>
              <div className="testimonial__stars">&nbsp;</div>
            </header>
            <p className="testimonial__text">
              Odporucam. Dobre ceny, super pristup jak zamestnancov tak aj majitelov. Mal som dost vela poziadaviek
              ohladom pozicania viacerych lyzi a po vzajomnej dohode som bol viac ako spokojny...
            </p>
          </div>
          <div className="testimonial">
            <header className="testimonial__header">
              <h2 className="testimonial__title">Boris Bielik</h2>
              <div className="testimonial__stars">&nbsp;</div>
            </header>
            <p className="testimonial__text">
              Starostlivost eno nuno, chodim k nim kazdy rok s lyzami, ktorym davam zabrat vo francuzskych Alpach. Super
              pomer cena/vykon
            </p>
          </div>
          <div className="testimonial">
            <header className="testimonial__header">
              <h2 className="testimonial__title">Tony Zee</h2>
              <div className="testimonial__stars">&nbsp;</div>
            </header>
            <p className="testimonial__text">
              Naozaj super spokojnost. Ochotny personal, perfektna robotka, bude sa jazdit ako na novom. Odporucam!!
            </p>
          </div>
        </div>
        <div className="testimonials__meta">
          <a
            target="_blank"
            className="testimonials__main-button"
            href="https://search.google.com/local/writereview?placeid=ChIJd3NqGHCFbEcRGLylPXKI33w"
          >
            Pridať recenziu
          </a>
          <a target="_blank" className="testimonials__secondary-button" href="https://g.co/kgs/NtskBx">
            Zobraziť všetky recenzie
          </a>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
