import React from 'react';
import LandingImage from '../images/landing-image.jpg';
import FeaturedNav from '../components/featured-nav/featured-nav';
import Hero from '../components/hero/hero';
import Layout from '../components/layout';
import Reviews from '../components/reviews/reviews';
import Sale from '../components/sale/sale';
import Contact from '../components/contact/contact';

const IndexPage = () => (
  <Layout>
    <Hero />
    <FeaturedNav />
    <img src={LandingImage} style={{ maxWidth: ' 100%', display: 'block', margin: '25px auto' }} />
    <Reviews />
    <Sale />
    <Contact />
  </Layout>
);

export default IndexPage;
