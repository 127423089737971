import React, { VoidFunctionComponent } from 'react';
import './hero.scss';

const Hero: VoidFunctionComponent = () => {
  return (
    <section className="hero" id="domov">
      <div className="hero__container container">
        <h1 className="hero__title">
          Predaj, servis, požičovňa, bazár a test centrum <span className="large">lyží a snowboardov</span> v Bratislave
        </h1>
      </div>
    </section>
  );
};

export default Hero;
